import React from "react";
import CardGrid, { mapActivity } from "./CardGrid";

const ActivityCardGrid = ({
  activities,
  style,
  cardTitleHeadingComponent,
  additionalCardStyle,
}) => (
  <CardGrid
    items={activities.map(mapActivity)}
    style={style}
    cardTitleHeadingComponent={cardTitleHeadingComponent}
    additionalCardStyle={additionalCardStyle}
  />
);

export default ActivityCardGrid;
