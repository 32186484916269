/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { Fragment, useState, useRef } from "react";
import FilterSection from "components/FilterSection";
import Pill from "./Pill";
import ActivityCardGrid from "./ActivityCardGrid";
import { getActivitiesMatchingPattern } from "../utils/SearchUtils";
import ColorMap from "utils/ColorMap";

import SearchIcon from "../assets/icons/search.svg";

const QuickActivityFinder = ({
  activities,
  activityGroups,
  prefilter = {},
}) => {
  const [filter, setFilter] = useState({
    manipulation: false,
    orientation: false,
    constellation: false,
    characters: false,
    literacy: false,
    numeracy: false,
    ...prefilter,
  });
  const filterActive = Object.values(filter).some(enabled => enabled);
  const [matchingActivities, setMatchingActivities] = useState(activities);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showMobileFilter, setShowMobileFilter] = useState(filterActive);
  const [showAllActivities, setShowAllActivities] = useState(false);
  const searchTimeoutId = useRef(null);
  const searchResultsRef = useRef(null);

  const onClickClearFilter = () => {
    if (filterActive) {
      setFilter({
        manipulation: false,
        orientation: false,
        constellation: false,
        characters: false,
        literacy: false,
        numeracy: false,
      });
    }
  };

  const onClickFilter = filterId => {
    const newFilter = {
      ...filter,
      [filterId]: !filter[filterId],
    };

    setFilter(newFilter);
  };

  const onSearchInputChange = event => {
    const trimmedSearchTerm = event.nativeEvent.target.value.trim();

    clearTimeout(searchTimeoutId.current);
    searchTimeoutId.current = setTimeout(() => {
      setSearchTerm(trimmedSearchTerm);
      updateMatchingActivities(trimmedSearchTerm);
    }, 200);
  };

  const updateMatchingActivities = searchPattern => {
    const activitiesMatchingSearchTerm = getActivitiesMatchingPattern(
      activities,
      searchPattern
    );

    setMatchingActivities(activitiesMatchingSearchTerm);
  };

  const onSearchInputKeyUp = event => {
    // Focus on search results when Enter is pressed
    if (event.nativeEvent.keyCode === 13) {
      searchResultsRef.current.focus();
    }
  };

  const filteredMatchingActivities = matchingActivities.filter(
    ({ activityGroup }) => !filterActive || filter[activityGroup.uid]
  );

  const sections = [
    {
      level: "Pre-braille",
      filters: activityGroups.filter(
        activityGroup => activityGroup.level === "Pre-braille"
      ),
    },
    {
      level: "Braille",
      filters: activityGroups.filter(
        activityGroup => activityGroup.level === "Braille"
      ),
    },
  ];

  const activeFilterNames = activityGroups
    .filter(group => filter[group.uid])
    .map(group => group.name);
  const numberOfFilters = activeFilterNames.length;
  let filtersString = "";

  if (numberOfFilters > 0) {
    const lastFilterName = activeFilterNames.pop();

    filtersString = ` in ${activeFilterNames.join(", ")}${
      numberOfFilters > 1 ? " and " : ""
    }${lastFilterName}`;
  }

  const resultsHeader = `${filteredMatchingActivities.length} ${
    filteredMatchingActivities.length === 1 ? "activity" : "activities"
  }${searchTerm ? ` for "${searchTerm}"` : ""}${filtersString}`;

  const searchInputAriaLabel = "Search in activities, press enter to submit";
  const numberOfActivitiesToDisplay = showAllActivities
    ? filteredMatchingActivities.length
    : 12;

  return (
    <div>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "60px",
        }}
      >
        <Themed.h2 sx={{ marginRight: "auto" }}>
          Quick activity finder
        </Themed.h2>
        <div
          sx={{
            display: ["none", "flex"],
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <label htmlFor="input">Search in activities</label>
          <div
            sx={{
              border: "1px solid",
              borderColor: "text",
              borderRadius: "4px",
              marginLeft: "1rem",
            }}
          >
            <input
              id="input"
              sx={{
                height: "50px",
                fontFamily: "body",
                padding: "0 1rem",
                color: "text",
                border: "1px solid transparent",
                borderRadius: "4px",
                fontSize: 0,
                lineHeight: 0,
                background: searchTerm
                  ? "transparent"
                  : `url(${SearchIcon}) transparent content-box no-repeat right center / 18px 20px`,
                ":focus": {
                  borderColor: "text",
                },
              }}
              type="search"
              maxLength={100}
              size={10}
              aria-label={searchInputAriaLabel}
              onChange={onSearchInputChange}
              onKeyUp={onSearchInputKeyUp}
            />
          </div>
        </div>
      </div>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          width: "100%",
          height: "auto",
          flexWrap: ["wrap", "nowrap"],
        }}
      >
        <div
          sx={{
            marginTop: [0, "48px"],
            marginRight: "2rem",
            marginBottom: "1rem",
          }}
        >
          <Pill
            label="Show all"
            ariaLabel="Show all"
            onClick={onClickClearFilter}
            active={!filterActive}
          />
        </div>
        <div sx={{ display: ["flex", "none"], flexDirection: "row" }}>
          <Pill
            label="Filter"
            ariaLabel="Show filter"
            onClick={() => setShowMobileFilter(!showMobileFilter)}
            active={showMobileFilter}
          />
          <input
            sx={{
              marginLeft: "1rem",
              height: "50px",
              width: "60px",
              flexGrow: searchTerm ? 1 : 0,
              fontFamily: "body",
              padding: "0 1rem",
              color: "text",
              border: "1px solid",
              borderColor: "text",
              borderRadius: "25px",
              fontSize: 0,
              lineHeight: 0,
              background: searchTerm
                ? "transparent content-box no-repeat 100% 50% / 18px 20px"
                : `url(${SearchIcon}) transparent content-box no-repeat 50% 50% / 18px 20px`,
              ":focus": {
                flexGrow: 1,
                backgroundPosition: "100% 50%",
              },
              transition: "flex-grow 100ms",
            }}
            type="search"
            maxLength={100}
            aria-label={searchInputAriaLabel}
            onChange={onSearchInputChange}
            onKeyUp={onSearchInputKeyUp}
          />
        </div>
        <div
          sx={{
            display: [showMobileFilter ? "flex" : "none", "flex"],
            marginTop: ["3rem", 0],
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {sections.map(({ level, filters }, index) => (
            <FilterSection
              key={level}
              level={level}
              filters={filters}
              header={<FilterHeader level={level} />}
              onClickFilter={onClickFilter}
              filterState={filter}
              style={{
                flex: [null, 0, 1 / sections.length],
                marginBottom:
                  index < sections.length - 1 ? ["1rem", "30px"] : 0,
              }}
            />
          ))}
        </div>
      </div>
      <div aria-live="polite">
        <Themed.h2 sx={{ variant: "text.h4", margin: "3rem 0" }}>
          {resultsHeader}
        </Themed.h2>
      </div>
      <nav
        ref={searchResultsRef}
        aria-label="Search results"
        tabIndex="-1"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          outline: "none",
        }}
      >
        <ActivityCardGrid
          activities={filteredMatchingActivities.slice(
            0,
            numberOfActivitiesToDisplay
          )}
          additionalCardStyle={{
            animation: `fadeIn 500ms`,
          }}
        />
        {numberOfActivitiesToDisplay < filteredMatchingActivities.length && (
          <Pill
            label={`Load more (${
              filteredMatchingActivities.length - numberOfActivitiesToDisplay
            })`}
            onClick={() => setShowAllActivities(true)}
            style={{ marginTop: "60px" }}
          />
        )}
      </nav>
    </div>
  );
};

export default QuickActivityFinder;

const FilterHeader = ({ level }) => (
  <Fragment>
    <span
      sx={{
        display: "inline-block",
        marginRight: "1rem",
        width: "15px",
        height: "15px",
        borderRadius: "4px",
        bg: ColorMap[level],
      }}
    />
    <span>{`${level} activities`}</span>
  </Fragment>
);
