import Fuse from "fuse.js";

const getActivitiesMatchingPattern = (activities, pattern = "") => {
  const trimmedPattern = pattern.trim();

  if (!trimmedPattern) {
    return activities;
  }

  const fuse = new Fuse(activities, {
    keys: ["title", "summary"],
    threshold: 0.4,
    minMatchCharLength: 2,
    distance: 1000,
  });

  return fuse.search(trimmedPattern).map(match => match.item);
};

export { getActivitiesMatchingPattern };
